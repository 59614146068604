'use client';

import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { useContext, useMemo } from 'react';
import DrawerContents from './DrawerContents';
import { styled, useTheme } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { getDrawerItems } from './DrawerItems';
import { AppContext } from 'context/AppProvider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import { useRouter } from 'navigation';
import { signOut, useSession } from 'next-auth/react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Image from 'next/image';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppDrawer = () => {
  const { status } = useSession();
  const theme = useTheme();
  const appContext = useContext(AppContext);
  const router = useRouter();

  // @ts-ignore
  const drawerItems = useMemo(
    () => getDrawerItems(appContext?.user?.role),
    [appContext?.user?.role],
  );

  const handleSignOut = async () => {
    await signOut({ callbackUrl: '/', redirect: false });
    router.push('/');
  };

  if (status !== 'authenticated') {
    return null;
  }

  const handleDrawerToggle = () => {
    if (appContext?.setDrawerToggle) {
      appContext.setDrawerToggle();
    }
  };

  const handleProfile = () => {
    router.push('/profile');
  };

  return (
    <Drawer
      variant="permanent"
      open={appContext.drawerOpen}
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.primary.main,
        },
      }}
    >
      <DrawerHeader>
        {appContext.drawerOpen && (
          <Image
            src="/assets/clarity-logo.svg"
            alt="Clarity Pediatrics logo"
            placeholder="blur"
            blurDataURL={'/assets/clarity-logo.svg'}
            width={100}
            height={32}
          />
        )}
        <IconButton onClick={handleDrawerToggle}>
          {appContext.drawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <DrawerContents contents={drawerItems} open={appContext.drawerOpen} />
      <List>
        <ListItem disablePadding onClick={handleProfile}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: 'initial',
              px: 2.5,
              '&:hover': { backgroundColor: theme.palette.primary.light },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: appContext.drawerOpen ? 3 : 'auto',
                justifyContent: 'center',
                color: theme.palette.text.primary,
              }}
            >
              <AccountCircleIcon />
            </ListItemIcon>
            {appContext.drawerOpen && (
              <ListItemText
                primary={'Profile'}
                sx={{ color: theme.palette.text.primary, opacity: 1 }}
              />
            )}
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding onClick={handleSignOut}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: 'initial',
              px: 2.5,
              '&:hover': { backgroundColor: theme.palette.primary.light },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: appContext.drawerOpen ? 3 : 'auto',
                justifyContent: 'center',
                color: theme.palette.text.primary,
              }}
            >
              <LogoutIcon />
            </ListItemIcon>
            {appContext.drawerOpen && (
              <ListItemText
                primary={'Sign Out'}
                sx={{ color: theme.palette.text.primary, opacity: 1 }}
              />
            )}
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default AppDrawer;
